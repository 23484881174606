/**
 * @file
 * A JavaScript file for the theme.
 *
 * In order for this JavaScript to be loaded on pages, see the instructions in
 * the README.txt next to this file.
 */
;(function ($, Drupal) {
  Drupal.behaviors.vavilon_behavior = {
    attach: function (context, settings) {
/**
 * arbore scripts
 */

'use strict';

// Material Slider
let owlMaterialsSlider = $('.materials-slider-wrapper');
if (owlMaterialsSlider.length !== 0) {
  owlMaterialsSlider.addClass('owl-carousel').owlCarousel({
    items: 4,
    responsive: {
      0: { items: 2 },
      480: { items: 3 },
      640: { items: 4 },
      768: { items: 4 },
      960: { items: 3 },
      1280: { items: 4 }
    },
    //slideBy: 4,
    nav: true,
    navContainer: '.materials-nav',
    navText: ['', ''],
    loop: true,
    dots: false,
    lazyLoad: true
  })
}


// Decorates Slider
let owlDecoratesSlider = $('.decorates-slider-wrapper');
if (owlDecoratesSlider.length !== 0) {
  owlDecoratesSlider.addClass('owl-carousel').owlCarousel({
    items: 4,
    responsive: {
      0: { items: 2 },
      480: { items: 3 },
      640: { items: 4 },
      768: { items: 4 },
      960: { items: 3 },
      1280: { items: 4 }
    },
    //slideBy: 4,
    nav: true,
    navContainer: '.decorates-nav',
    navText: ['', ''],
    loop: true,
    dots: false,
    lazyLoad: true
  })
}


// Frontpage Slider
let owlFrontSlider = $('.view-slider .view-content .view-content-wrapper');
if (owlFrontSlider.length !== 0) {
  owlFrontSlider.addClass('owl-carousel').owlCarousel({
    items: 1,
    loop: true,
    nav: true,
    navContainer: '.slider-front-nav',
    navText: ['Назад', 'Вперед'],
    dots: true,
    dotsEach: true,
    dotsContainer: '.slider-front-dots',
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 4000,
    autoplayTimeout: 7000,
    autoplayHoverPause: false,
    onInitialize: imageDataInit,
    onTranslate: imageDataChange
  })
  .on('mouseover', function () {
    owlFrontSlider.trigger('stop.owl.autoplay');
  })
  .on('mouseleave', function () {
    owlFrontSlider.trigger('play.owl.autoplay');
  });
}

/**
 * Initialization of image data
 */
function imageDataInit() {
  let shadow = '15, 3, 0'; //#0f0300
  let img = owlFrontSlider.find('.slide-row').find('.slide-image').data('img');
  let bgCss = 'linear-gradient(to bottom, rgba('+shadow+', 0.5), rgba('+shadow+', 0.5)), url("'+img+'")';
  $('.front-wrapper-wrap').css('backgroundImage', bgCss);
}

/**
 * Change image data
 * @param event
 */
function imageDataChange(event) {
  let delay = 2600;
  let id = event.item.index;
  let shadow = '15, 3, 0'; //#0f0300
  let img = owlFrontSlider.find('.slide-row').eq(id).find('.slide-image').data('img');
  let bgCss = 'linear-gradient(to bottom, rgba('+shadow+', 0.5), rgba('+shadow+', 0.5)), url("'+img+'")';
  $('.front-wrapper').css('backgroundImage', bgCss);
  $('.front-wrapper-wrap').animate({'opacity': 0}, delay, function() {
    $('.front-wrapper-wrap').css('backgroundImage', bgCss);
  }).animate({'opacity': 1}, delay);
}

if ($.fn.owlCarousel) {

  // Product Images Slider
  let owlProductImgSlider = $('.product-image-slider');
  if (owlProductImgSlider.length !== 0) {
    owlProductImgSlider.addClass('owl-carousel').owlCarousel({
      items: 1,
      nav: true,
      navContainer: '.product-nav',
      navText: ['Назад', 'Вперед'],
      loop: true,
      dots: true,
      dotsEach: true,
      dotsContainer: '.product-image-pages',
      lazyLoad: true,
      autoplay: true,
      autoplaySpeed: 4000,
      autoplayTimeout: 7000,
      autoplayHoverPause: false,
    })
      .on('mouseover', function () {
        owlProductImgSlider.trigger('stop.owl.autoplay');
      })
      .on('mouseleave', function () {
        owlProductImgSlider.trigger('play.owl.autoplay');
      });
  }

  // Catalog Slider
  let owlCatalogSlider = $('.catalog-slider ul');
  if (owlCatalogSlider.length !== 0) {
    owlCatalogSlider.addClass('owl-carousel').owlCarousel({
      items: 5,
      responsive: {
        0: {items: 1},
        480: {items: 2},
        768: {items: 3},
        1280: {items: 4},
        1600: {items: 5}
      },
      nav: true,
      navContainer: '.catalog-nav',
      navText: ['Назад', 'Вперед'],
      loop: true,
      dots: false,
      lazyLoad: true,
      autoplay: true,
      autoplaySpeed: 4000,
      autoplayTimeout: 7000,
      autoplayHoverPause: false,
      //animateOut: 'fadeOut',
    })
      .on('mouseover', function () {
        owlCatalogSlider.trigger('stop.owl.autoplay');
      })
      .on('mouseleave', function () {
        owlCatalogSlider.trigger('play.owl.autoplay');
      });
  }

}


// Phone input masks
if ($.fn.inputmask) {
  $('input[name="submitted[callback_phone]"], input[name="submitted[calculate_phone_email]"]').inputmask('+7 999 999-99-99', {placeholder: '*'});
}


// Sidebar expander
$(document).on('click', '#sidebar-expander', function () {
  $('#sidebar-categories').toggleClass('active');
  $(this).toggleClass('active');
});


// Scrolls to IDs
if ($.fn.mPageScroll2id) {
  $('.sidebar-contacts-item a[href^="#"]').mPageScroll2id();
  $('.scroll-down a, .scroll-up a').mPageScroll2id();
}


// Show production menu
let menu = $('#header').find('.header-menu');

$(document).on('click', '.header-menu ul a[href="/production"]', function (e) {
  e.preventDefault();
  $(this).toggleClass('active');
  menu.find('li > ul').eq(0).toggleClass('active');
});

$(document).on('click', '.footer-menu ul.menu a[href="/production"]', function (e) {
  e.preventDefault();
  menu.find('a[href="/production"]').trigger('click').toggleClass('active');
  menu.find('li > ul').eq(0).toggleClass('active');
});

$(document).on('click', function (e) {
  let link = menu.find('a[href="/production"]');
  if (!link.is(e.target) && link.has(e.target).length === 0) {
    link.removeClass('active');
    menu.find('li > ul').removeClass('active');
  }
});
$(document).on('scroll', function () {
  let link = menu.find('a[href="/production"]');
  link.removeClass('active');
  menu.find('li > ul').removeClass('active');
});


// Portfolio
$('a.bef-toggle').on('click', function () {
  $(this).toggleClass('clear');
});

$(document).on('click', 'ul.bef-tree .form-type-bef-checkbox label', function () {
  if (!$(this).closest('ul').hasClass('bef-tree-child')) {
    return false;
  }
});

if ($.fn.SuperBox) {
  $('.view-portfolio .view-content').SuperBox();
}


// Modals
$(document).on('click', '#button-order', function () {
  $('#order-modal').modal('show');
});

$(document).on('click', '#button-question', function () {
  $('#solution-modal').modal('show');
});

$(document).on('click', 'a[href="/calculate"]', function () {
  $('#calculate-modal').modal('show');
  return false;
});


// Mobile main menu
$(document).on('click', '.mobile-menu-exp', function () {
  $('.mobile-menu').toggleClass('active');
});

$(document).on('click', '.mobile-menu .close', function () {
  $('.mobile-menu').removeClass('active');
});

$(document).on('click', '.mobile-menu ul > li.expanded.menu-mlid-359 > a', function () {
  $(this).toggleClass('active').siblings('ul').toggleClass('active');
  return false;
});


// Autoupload attached files
$('form', context).delegate('input.form-file', 'change', function() {
  $(this).next('input[type="submit"]').mousedown();
});


/**
 * Handle mouse coordinates
 * @param event
 * @returns {{x: number|*, y: number|*}}
 */
function handleMouseMove(event) {
  let dot, eventDoc, doc, body, pageX, pageY;
  event = event || window.event; // IE-ism
  // If pageX/Y aren't available and clientX/Y are,
  // calculate pageX/Y - logic taken from jQuery.
  // (This is to support old IE)
  if (event.pageX == null && event.clientX != null) {
    eventDoc = (event.target && event.target.ownerDocument) || document;
    doc = eventDoc.documentElement;
    body = eventDoc.body;
    event.pageX = event.clientX +
      (doc && doc.scrollLeft || body && body.scrollLeft || 0) - (doc && doc.clientLeft || body && body.clientLeft || 0);
    event.pageY = event.clientY +
      (doc && doc.scrollTop  || body && body.scrollTop  || 0) - (doc && doc.clientTop  || body && body.clientTop  || 0 );
  }
  return {
    x: event.pageX,
    y: event.pageY
  };
}

// Open sidebar for mouse move to window left border
$(document).on('mousemove', function (e) {
  let coord = handleMouseMove(e);
  if (coord.x < 2 ) {
    $('#sidebar-categories, #sidebar-expander').addClass('active');
  }
});

$(document).on('click', 'a[href="/order"], a[href="/solution"], a[href="/calculate"], a[href="/designers"]', function (e) {
  e.preventDefault();
});


// Swipes
if ($.fn.swipe) {
  if ($('.sidebar-categories') || $('.sidebar-contacts')) {
    // Sidebar swipes
    $('body').swipe({
      swipeStatus: function (event, phase, direction, distance, duration, fingers) {
        if (phase === 'move' && direction === 'right') {
          $('#sidebar-categories, #sidebar-expander').addClass('active');
          return false;
        }
        if (phase === 'move' && direction === 'left') {
          $('#sidebar-categories, #sidebar-expander').removeClass('active');
          return false;
        }
      }
    });
  }
}


// Fancybox init.
if ($.fn.fancybox) {
  $('[data-fancybox]').fancybox({
    toolbar  : true,
    smallBtn : false,
    loop: false,
    buttons : [
      'close'
    ]
  });
}


// Page title to webform hidden field (page)
let title = $('title').text().split('|')[0];
$('input[name="submitted[calculate_page]"]').val(title);
$('input[name="submitted[callback_page]"]').val(title);


//---------------------------
    }
  };
})(jQuery, Drupal);
